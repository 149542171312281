* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

li:not(:last-child) {
  margin-bottom: 8px;
}

p:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 72px;
}
h2 {
  font-size: 48px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 30px;
  }
}
